import React, { PureComponent } from 'react';

import MainScreen from './Blocks/MainScreen';
import './WelcomePage.css';

export default class WelcomePage extends PureComponent {
    render() {
        return (
            <div>
                <MainScreen />
            </div>
        )
    }
}
